(function () {

  if (!document.getElementById('singleImage')) return this;

  const singleImage = document.getElementById('singleImage');
  const singlePost = document.getElementById('singlePost');

  singlePost.classList.add('loading');

  singleImage.onload = function (e) {
    singleImage.classList.add('show');
    singlePost.classList.remove('loading');
  };

})();
