import objectFitImages from 'object-fit-images';
objectFitImages();

import './_home.js';
import './_single.js';

const webfont = document.createElement('link');
webfont.rel = 'stylesheet';
webfont.href = 'https://use.typekit.net/zll6xjx.css';
webfont.onload = function () {
  console.log('link 読み込み完了');
};

document.body.appendChild(webfont);
