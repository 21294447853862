import Masonry from 'masonry-layout';
import axios from 'axios';

(function () {

  if (!document.getElementById('home')) return this;

  const coverElement = document.getElementById('home');
  const infoElement = document.getElementById('info');
  coverElement.style.height = infoElement.style.height = window.innerHeight + 'px';

  window.addEventListener('resize', function (e) {
    coverElement.style.height = infoElement.style.height = window.innerHeight + 'px';
  });

  const instance = axios.create({
    baseURL: '', // バックエンドB のURL:port を指定する
    headers: {
      ContentType: 'text/html',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    },
    responseType: 'text',
  });

  const photoWall = new Masonry('.post-container', {
    transitionDuration: '1s',
    stagger: 0,
    percentPosition: true,
    columnWidth: '.post',
    itemSelector: '.post',
  });

  const posts = document.querySelectorAll('.post');

  const getPhotoURL = function (posts, count = 0) {
    const post = posts[count];
    if (!post) return;
    const image = new Image();
    const anchor = post.querySelector('a');
    post.classList.add('loading');
    image.onload = function (e) {
      post.classList.remove('loading');
      image.classList.add('show');
      photoWall.layout();
    };

    instance.get(anchor.href + '?get')
    .then(function (response) {
      image.src = response.data;
      image.alt = '写真：' + anchor.title;
      anchor.appendChild(image);
      count += 3;
      getPhotoURL(posts, count);
    })
    .catch(function (error) {
      post.classList.remove('loading');
      console.log(error, 'ERROR!! happend by Backend.');
    });
  };

  getPhotoURL(posts, 0);
  getPhotoURL(posts, 1);
  getPhotoURL(posts, 2);

  window.addEventListener('wheel', function (e) {
    if (0 > e.deltaY && 0 >= window.scrollY && !infoElement.classList.contains('open')) {
      infoElement.classList.add('open');
    }else if (0 < e.deltaY && 0 < window.scrollY && infoElement.classList.contains('open')) {
      infoElement.classList.remove('open');
    }
  });

  window.addEventListener('touchend', function (e) {
    console.log('end', 'window', window.scrollY);
    if (0 > window.scrollY && !infoElement.classList.contains('open')) {
      infoElement.classList.add('open');
    }else if (0 < window.scrollY && infoElement.classList.contains('open')) {
      infoElement.classList.remove('open');
    }
  });

  const closeInfoContainer = document.getElementById('closeInfoContainer');
  closeInfoContainer.onclick = function (e) {
    infoElement.classList.remove('open');
  };

  const openInfoContainer = document.getElementById('openInfoContainer');
  openInfoContainer.onclick = function (e) {
    infoElement.classList.add('open');
  };

  const formElement = document.querySelector('.mw_wp_form');
  if (
    formElement.querySelector('.error') ||
    formElement.classList.contains('mw_wp_form_confirm') ||
    formElement.classList.contains('mw_wp_form_complete')
  ) {
    infoElement.classList.add('open');
  };

})();
